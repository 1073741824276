.btn {
  outline: none;
  border: none;
  height: 4rem;
  color: $text-black;


  border-radius: calc($border-radius / 2);
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $md-font;
  padding: 0.5rem 1.5rem;

  &-plain {
    background-color: $white-color;
    color: $primary-color;
    border: $border;
  }

  &-primary {
    background-color: $primary-color;

    &.duotone {
      background-color: rgba($color: $primary-color, $alpha: 0.3);
      color: $primary-color;
    }
  }

  &-error {
    background-color: $error-color;

    &.duotone {
      background-color: rgba($color: $error-color, $alpha: 0.3);
      color: $error-color;
    }
  }

  &-success {
    background-color: $success-color;

    &.duotone {
      background-color: rgba($color: $success-color, $alpha: 0.3);
      color: $success-color;
    }
  }

  &-warning {
    background-color: $warning-color;
    &.duotone {
      background-color: rgba($color: $warning-color, $alpha: 0.3);
      color: $warning-color;
    }
  }

  &-secondary {
    background-color: $white-color;
    color: $text-black;
    border: $border;
  }

  &-link {
    color: $primary-color;
    text-decoration: underline;
    height: max-content !important;
  }
}

 .bttn {
  padding: .5rem 1rem;
  border-radius: .3rem;
  background-color: transparent;
  font-size: $sm-font;
  font-weight: 500;
  transition: .2s;
  cursor: pointer;
  margin: 0 .5rem;
}

.edit-btn {
  border: $border-primary;
  color: $primary-color;

  &:hover {
      background-color: $primary-color;
      color: white;
  }
}

  .delete-btn {
  border: $border-error;
  color: $error-color;

  &:hover {
      background-color: $error-color;
      color: white;
  }
}