.performance-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: $primary-color;
    

    & .performance-top {
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h1 {
            font-size: $xxl-font;
            font-weight: 700;
            text-transform: capitalize;
        }
    }

    & .candidate-container {
        width: 100%;
        text-align: center;

        & .card-wrapper {
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            gap: 3.6rem;

        }


    }
}