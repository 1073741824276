$dstop-height: 5rem;
$avatar-top: calc($dstop-height / 2);

.card {
    position: relative;
    display: block;
    width: 20rem;
    border-radius: 10px;
    box-shadow: 0 3px 5px 5px rgba(0, 0, 0, 0.1);
    background: $primary-color;
    overflow: hidden;
    color: white;
    padding-inline: 10px;

    .ds-top {
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: $dstop-height;
        background: $alpha-primary-color;
        animation: dsTop 1s;
    }

    .avatar-holder {
        position: absolute;
        margin: auto;
        top: $avatar-top;
        right: 0;
        left: 0;
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        background: transparent;
        overflow: hidden;
        animation: mvTop 1s;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .info {
        margin-top: $dstop-height+5rem;
        text-align: center;
        min-height: 5rem;
        animation: fadeIn 1s ease-in;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 1rem;

        & .name {
            text-align: center;
            animation: fadeIn 2s ease-in;


            & .title {
                // font-size: $md-font;
                font-weight: 700;
            }

            & .candidate-name {
                // font-size: $sm-font;
                font-weight: 500;
            }
        }

        & .votes {
            font-size: $xl-font;
            font-weight: 700;

            & h1 {
                line-height: 2.3rem;
            }
        }
    }

}



@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}




@keyframes mvTop {
    0% {
        top: -5rem;
    }

    100% {
        top: $avatar-top;
    }
}

@keyframes dsTop {
    0% {
        top: -5rem;
    }

    100% {
        top: 0;
    }
}