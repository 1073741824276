
.e-container{
    width: 100%;
    margin-top: 5rem;

    & .e-box{
        width: 100%;
        padding: 3rem 3rem;
        display: flex;
        flex-direction: column;
        border-radius: .5rem;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border: 2px solid rgba(0, 0, 0, 0.064);
        background-color: white;
        
        & .userselect{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            & .user-input{
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                font-size: $md-font;
                font-weight: 400;
            }
            
            & p{
                color:$black-color;
            }
            
            & select{
                width: 100%;
                padding: 1.3rem 1rem;
                border: 1.5px solid rgba(44, 44, 44, 0.132);
                outline: .5px solid $primary-color;
                border-radius: 5px;
                font-size: $sm-font;
                margin-bottom:3rem;
                margin-top: 1rem;

                &:focus{
                    border: 1.5px solid $primary-color;
                }
                
            }
        }
    }

}