@import './notifications/notification';
@import './manageplan/manageplan';
@import './paymentgateway/payment';
@import './faq/faq';
@import './notifications/notification';
@import './reports/report';



// Add Entry

.form-wrapper {
    width: 100%;
    background-color: white;
    padding: 3rem 5rem;
    margin-top: 5rem;
    border: 2px solid $lightborder-color;
    border-radius: 8px;

    & .form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;


        & label {
            color: $text-black;
            font-size:$md-font;
        }

        & .input {
            width: 31.56%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;




            & select {
                border: $border-black;
                border-radius: calc($border-radius / 2);
                height: 4rem;
                width: 100%;
                outline: none;
                font-size: $md-font;
                background-color: $white-color;
                color: black;
                font-weight: 400;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 1rem 1rem;
                gap: 2rem;
                margin-top: .5rem;
            }

            & textarea {
                border: $border-black;
                border-radius: calc($border-radius / 2);
                outline: none;
                font-size: $md-font;
                background-color: $white-color;
                color: black;
                font-weight: 400;
                padding: 1rem 1rem;
                resize: none;
                margin-top: .5rem;
                width: 100%;

                &.h-128{
                    height: 18rem;
                }


                &::-webkit-resizer {
                    appearance: none;
                }

                &:focus {
                    border: $border-primary;
                }
            }
        }

        & .textarea {
            width: 100%;
        }

        & .btn-wrapper {
            width: 100%;

            & .form-btn {
                background-color: $primary-color;
                color: white;
                padding: 2.3rem 8rem;
                min-width: 300px;
                margin: 0 auto;
                border-radius: 24px;


            }
        }
    }
    & .form-org {
        width: 100%;
        gap: 3rem;


        & label {
            color: $text-black;
            font-size:$md-font;
        }

        & .input {
            width: 31.56%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;




            & select {
                border: $border-black;
                border-radius: calc($border-radius / 2);
                height: 4rem;
                width: 100%;
                outline: none;
                font-size: $md-font;
                background-color: $white-color;
                color: black;
                font-weight: 400;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 1rem 1rem;
                gap: 2rem;
                margin-top: .5rem;
            }

            & textarea {
                border: $border-black;
                border-radius: calc($border-radius / 2);
                outline: none;
                font-size: $md-font;
                background-color: $white-color;
                color: black;
                font-weight: 400;
                padding: 1rem 1rem;
                resize: none;
                margin-top: .5rem;
                width: 100%;

                &.h-128{
                    height: 18rem;
                }


                &::-webkit-resizer {
                    appearance: none;
                }

                &:focus {
                    border: $border-primary;
                }
            }
        }

        & .textarea {
            width: 100%;
        }

        & .btn-wrapper {
            width: 100%;

            & .form-btn {
                background-color: $primary-color;
                color: white;
                padding: 2.3rem 8rem;
                min-width: 300px;
                margin: 0 auto;
                border-radius: 24px;


            }
        }
    }

}

