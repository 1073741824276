.faq-page {
    width: 100%;
    padding-bottom: 2rem;


    & .add-qa {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding: 3rem 0;
        border-bottom: $border;

        & .title {
            font-size: $xl-font;
            color: $primary-color;
        }

        & .add-qa-input {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }

        & p {
            color: $black-color;

        }
    }

    & .display-faq {
        padding: 3rem 0;

        & .faq-lists {
            display: flex;
            flex-direction: column;
            gap: 1.2rem;
            border-radius: 1rem;

            & li {
                list-style: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 1rem;
                padding: 1.5rem 0;
                background-color: $lightborder-color;
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;


                & .question-wrapper {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 2rem;

                    & .action {
                        display: flex;
                        align-items: center;
                        font-size: 2.4rem;
                        gap: 2rem;
                    }


                    & .question {
                        font-size: $xl-font;
                        font-weight: 400;
                        display: flex;
                        align-items: center;
                        gap: 1rem;

                    }

                    & .del-icon {
                        color: $error-color;
                        cursor: pointer;
                    }

                    & .edit-icon {
                        color: green;
                        cursor: pointer;
                    }
                }

                & .answer-wrapper {
                    padding: 0 5rem;
                    overflow: hidden;
                    padding-right: 8rem;

                }

                & .answer {
                    font-size: 1.5rem;
                    font-weight: 400;
                    color: rgb(92, 92, 92);
                    line-height: 2.5rem;
                }

            }
        }
    }
}