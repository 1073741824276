.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  &-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    &--icon__bar,
    &--icon__back {
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white-color;
      border: $border;
      border-radius: $border-radius;
      color: $text-black;
      cursor: pointer;
    }
    &--text {
      color: $text-black;
      font-size: $lg-font;
      font-weight: 600;
    }
  }
  &-right {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    &--item {
      list-style: none;
      cursor: pointer;

      i {
        width: 3rem;
        height: 3rem;
        font-size: $md-font;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius;
        background-color: rgba($color: $primary-color, $alpha: 0.15);
        color: $primary-color;
      }
      span {
        font-size: $sm-font;
        color: $text-black;
      }
      &:active {
        opacity: 0.8;
      }
      &__logout i {
        background-color: rgba($color: $error-color, $alpha: 0.15) !important;
        color: $error-color !important;
      }
      &__logout span {
        color: $error-color !important;
      }
    }

    &--profile {
      img {
        border-radius: $border-radius !important;
        overflow: hidden;
        width: 3rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }
}
