:root{
  --c-primary:#0b005a;
  --c-secondary:#cc0177;
}
.spinner {
  &-box{
    width: 100px;
    height: 100px;
    display: flex;
    margin-inline: auto;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    &-quarter{
        width: 50px;
        height: 50px;
        border: 3px solid var(--c-primary);
        border-top: 3px solid transparent;
        border-radius: 50%;
        animation: spin 1s linear 0s infinite;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}