@import './default';

@include media-lg {
    @media screen and (max-width: 1100px) {
        .sidebar {
            position: fixed;
            z-index: 9;
            top: 0;
            left: 0;
        }

        .framecontainer {
            width: 100vw;

            &-content {
                margin-left: 50px;
            }
        }
    }
}

@include media-md {
    :root {
        font-size:55%;
      }
    
    .form-wrapper {
        padding: 5rem 2rem;

        & .form {
            display: flex;
            flex-direction: column;

            & .input {
                width: 100%;
            }
        }
    }

    
    


}