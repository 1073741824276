@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
  // font-family: "Poppins", sans-serif;
}

:root {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}





@import "./default";
@import "../ui/ui";
@import "../components/components";
@import '../pages/pagestyle';
@import "../pages/login/login";
@import "../pages/dashboard/dashboard";
@import "./responsive";
