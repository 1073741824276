.loginform {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid $primary-color;
  width: 400px;
  max-width: 90%;
  background-color: $white-color;
  border-radius: $border-radius;

  &-loginformtop {
    background: $primary-color;
    padding: 2rem;
    border-radius: $border-radius $border-radius 0 0;

    &--loginformtoptext {
      position: relative;
      padding-bottom: 2rem;

      & h1 {
        font-size: $xl-font;
        font-weight: 500;
        line-height: 2rem;
        color: $white-color;
        text-align: center;
        text-shadow: 4px 4px 4px $black-color;
        letter-spacing: 6px;
        text-transform: uppercase;
      }

      & h2 {
        font-size: $md-font;
        font-weight: 500;
        line-height: 5rem;
        color: $white-color;
        text-align: center;
        // text-shadow: 1px 1px 4px $black-color;
        letter-spacing: 2px;
      }
    }

    &--loginformtopimage {
      position: absolute;
      width: 100%;
      left: 0%;
      top: 24%;
      right: 0%;
      display: flex;
      justify-content: center;

      &_image {
        width: fit-content;
        height: 8rem;
        padding: 1rem;
        background-color: $white-color;
        box-shadow: 1px 1px 3px #eaeaea;
        border: 2px solid $primary-color;
        border-radius: $border-radius;

        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &-loginformform {
    padding: 6rem 2rem 2rem 2rem;
    background: $white-color;
    // border: 2px solid $primary-color;
    border-top: none;
    border-radius: 0 0 $border-radius $border-radius;

    &--flex {
      display: flex;
      flex-direction: column;
      gap: 1.4rem;
    }

    &--submit {
      display: flex;
      margin-top: 1.4rem;
      justify-content: flex-end;
    }
  }
}