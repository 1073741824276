.sidebar {
  background-color: $primary-color;
  border-right: $border;
  height: 100vh;
  transition: width 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  padding: 2rem 0;
  overflow: auto;

  &-top {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;

    &--logo {
      padding: 0 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-title {
        display: flex;
        align-items: center;
        gap: 1rem;

        & h1 {
          color: white;
          font-weight: 500;
          font-size: $xxl-font;
          letter-spacing: 0.2rem;
        }
      }

      &__back {
        height: 3.5rem;
        width: 3.5rem;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: $background-color;
        border: 1px solid $lightborder-color;
        // box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        cursor: pointer;
      }

      &__icon {
        height: 5rem;
        // overflow: hidden;
        object-fit: contain;
        border-radius: 1rem;
      }
    }

    &--menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      // gap: 0.5rem;
      &-innermenu {
        padding-left: 2.5rem;
        overflow: hidden;

        & .icon {
          font-size: $xl-font;
          color: $text-black;
        }
      }

      &__item.close {
        a {
          justify-content: center;
        }

        i {
          text-align: center;
        }
      }

      $sidebar-font-size: 1.5rem;
      &__item.active {
        background-color: rgba(0, 0, 0, 0.037);
        position: relative;

        span {
          font-size: $sidebar-font-size;
          color: $alpha-primary-color;
        }

        i {
          color: $alpha-primary-color;
          position: relative;

          &::after {
            content: "";
            width: 0.9rem;
            height: 0.9rem;
            background-color: $error-color;
            border-radius: 50%;

            position: absolute;
            top: -0.5rem;
            right: 0.4rem;
            // border: 0.25rem solid rgba($color: $primary-color, $alpha: 0.2);
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 1%;
          bottom: 1%;
          right: 0.5%;
          width: 0.4rem;
          border-radius: $border-radius 0 0 $border-radius;
          background-color: $primary-color;
        }
      }

      &__item {
        padding: 1rem 1rem;
        list-style: none;
        display: flex;
        justify-content: space-between;
        border-radius: 8px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.037);
        }

        a {
          display: flex;
          // gap: 1rem;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
        }

        &-icon {
          font-size: 1.6rem;
          color: white;
          cursor: pointer;
        }
        & .icon {
          font-size: $sm-font;
          margin-right: 1rem;
          color: white;
          cursor: pointer;
        }

        i {
          width: 3rem;
          border-radius: 50%;
          font-size: $sidebar-font-size;
          color: white;
        }

        span {
          font-size: $sidebar-font-size;
          font-weight: 400;
          color: white;
        }

        &:active {
          opacity: 0.8;
        }
      }
    }
  }

  &-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    width: 100%;

    &--actions {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 1rem;

      &__item {
        padding: 0 3rem;
        list-style: none;
        display: flex;
        align-items: center;

        gap: 1rem;
        cursor: pointer;

        i {
          width: 3rem;
          height: 3rem;
          font-size: $md-font;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: $border-radius;
          background-color: rgba($color: $primary-color, $alpha: 0.2);
          color: white;
        }

        span {
          font-size: $md-font;
          color: $text-black;
        }

        &:active {
          opacity: 0.8;
        }

        &-logout i {
          background-color: rgba($color: $error-color, $alpha: 0.2) !important;
          color: rgba($color: $error-color, $alpha: 1) !important;
        }

        &-logout span {
          color: rgba($color: $error-color, $alpha: 1) !important;
        }
      }

      &__profile {
        padding: 0 3rem;

        img {
          border-radius: $border-radius !important;
          overflow: hidden;
          width: 3rem;
          height: 3rem;
          object-fit: cover;
        }
      }
    }
  }

  .divider {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;

    &-item {
      width: 0.3rem;
      height: 0.3rem;
      background-color: rgba($color: $black-color, $alpha: 0.2);
      border-radius: 50%;
    }
  }
}
