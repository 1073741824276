.plan-box{
    padding: 3rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    border-radius: .8rem;
    background-color: white;
    width: 38rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    &-top{
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        width: 100%;
        margin: 0 auto;
        background-color: $white-color;
        border: 2px solid black;
        border-radius: .8rem;
        text-align: center;

        & .title{
            color: $primary-color;
            font-size: $lg-font;
            font-weight: 500;
        }
        & .desc{
            font-size: $sm-font;
            
        }
    }

    &-dets{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 3rem;

        & h1{
            font-size: $xl-font;
            font-weight: 500;
            display: flex;
            align-items: flex-start;
            gap: .5rem;

            & .prev-price{
                font-size: $md-font;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.406);
                text-decoration:line-through;
            }
            

        }
        & p{
            font-size:$md-font;
            color: $black-color;
        }

        & .plan-list{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:.8rem;
            color: $black-color;

            & li{
                list-style: none;
                display: flex;
                align-items: center;
                
                gap: 1rem;

                & .icon{
                    color: $success-color;
                }
            }
        }

    }

    &-bottom{
        width: 100%;
        text-align: center;

        & .delete-button{
            padding: 1.3rem 5rem;
            background-color: $primary-color;
            color: white;
            border: none;
            border-radius: .5rem;
            font-weight: 500;
            font-size:$md-font;
            border: 2px solid $primary-color;
            transition: .2s;
            &:hover{
                background-color: transparent;
                border: 2px solid $primary-color;
                color: $primary-color;
            }
        }
    }
}