.report{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5rem;

    & .report-title{
        font-size: $xxl-font;
        font-weight: 700;
        color: $primary-color;
        text-align: left;
    }

    & .voting-trend{
        width: 100%;
    }
    & .status{
        width: 100%;
    }
}