.dashboard {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  width: 100%;

  &-section-title {
    font-size: $xxl-font;
    font-weight: 500;
  }

  &-cards {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 3rem;
    padding-top: 2rem;

    &--item {
      width: 25%;
      background-color: $white-color;
      border: $border;
      border-radius: calc($border-radius / 2);
      padding: 1.5rem 1rem;
      display: flex;
      justify-content: space-between;

      &__icon {
        width: 4rem;
        height: 4rem;
        background-color: $primary-color;
        border-radius: calc($border-radius);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $text-white;
        transform: translateY(-90%);
        box-shadow: $box-shadow, $box-shadow;

        i {
          font-size: $sm-font;
        }
      }

      &__details {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 1rem;

        &-title {
          color: $text-black;
          font-size: 1.6rem;
          font-weight: 500;
        }

        &-number {
          color: $primary-color;
          font-size: 2.8rem;
        }
      }
    }
  }

  &-icon {
    display: flex;
    justify-content: flex-end;

    img {
      width: 30rem;
      height: 30rem;
      object-fit: contain;
      position: fixed;
      right: 1%;
      bottom: 1%;
    }
  }
}

/* Mobile */
@media (max-width: 700px) {}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
}

@media (prefers-color-scheme: dark) {}
